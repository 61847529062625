import React from 'react';
import './App.css';

// import {HashRouter, Route, Switch} from 'react-router-dom';
const App: React.FC = () => {
  return (
    <div className="App">
      hello word
      {/* <div><a href="/avalon">/avalon</a></div>
      <div><a href="/pintu?auto=1&speed=10&wcount=10">/pintu</a></div> */}
    </div>
  );
}

export default App;
