
import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import async from './async'
import "./index.css"
let PinTu = async(() => import("./game/pintu/app"));
let Avalon = async(() => import("./game/avalon/app"));
let Avalon_played = async(() => import("./game/avalon/played_avalon"));

let Profile = async(() => import("./game/profile/app"));

// let InputDemo = async(() => import("./InputDemo"));

// import { Route, Router } from "react-router";
// import { Router, Route, H } from 'react-router';
// import * as serviceWorker from './serviceWorker';


ReactDOM.render((


  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={App} />
      {/* both /roster and /roster/:number begin with /roster */}

      <Route path='/lifeboat' component={async(() => import("./game/lifeboat/app"))} />
      <Route path='/demo' component={async(() => import("./game/demo/app"))} />
      <Route path='/xmpp' component={async(() => import("./game/xmpp/app"))} />
      <Route path='/profile' component={Profile} />
      <Route path='/pintu_splash' component={async(() => import("./game/pintu/splash/app"))} />
      <Route path='/pintu' component={PinTu} />
      <Route path='/avalon_played' component={Avalon_played} />
      <Route path='/shenglv-page' component={async(() => import("./game/avalon/shenglv-page"))} />
      <Route path='/avalon' component={Avalon} />
      <Route path='/im' component={async(() => import("./game/im/app"))} />
      <Route path='/multi-user' component={async(() => import("./game/multi-user/app"))} />
      {/* <Route path='/avalon2' component={async(() => import("./game/avalon2/app"))} /> */}
      {/* <Route path='/schedule' component={Schedule}/> */}
    </Switch>
  </BrowserRouter>

  //     {/* <IndexRoute component={App}/> */}
  //     {/* <Route path="about" component={About} />
  //     <Route path="inbox" component={Inbox}>
  //       <Route path="messages/:id" component={Message} />
  //     </Route> */}
  // </BrowserRouter>
), document.getElementById('root'))

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
